import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import CustomerApp from "./containers/CustomerApp/App";
import asyncComponent from "./helpers/AsyncFunc";

const RestrictedAdminRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/admin-authen",
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);
const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const PublicRoutes = ({ history, isLoggedIn }) => {
    return (
        <ConnectedRouter history={history}>
            <div>
                <RestrictedRoute exact path={"/"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerHome"))} />

                <RestrictedRoute exact path={"/deposit"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu"))} />
                <RestrictedRoute exact path={"/depositMenu/deposit"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/auto"))} />
                <RestrictedRoute exact path={"/depositMenu/qrcode"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/qrcode"))} />
                <RestrictedRoute exact path={"/depositMenu/bitpayz/qr/show"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/bitpayz.qr.show"))} />

                <RestrictedRoute exact path={"/withdraw"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Withdraw"))} />
                <RestrictedRoute exact path={"/gameList"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerGameList"))} />
                <RestrictedRoute exact path={"/918"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Lobby/918"))} />
                <RestrictedRoute exact path={"/PUSSY"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Lobby/pussy"))} />
                <RestrictedRoute exact path={"/PGSLOT"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Lobby/pgslot"))} />
                <RestrictedRoute exact path={"/JOKER"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Lobby/joker"))} />
                <RestrictedRoute exact path={"/MEGA888"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Lobby/mega"))} />
                <RestrictedRoute exact path={"/JILI"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Lobby/jili"))} />
                <RestrictedRoute exact path={"/PRAGMATIC"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Lobby/pragmatic"))} />
                <RestrictedRoute exact path={"/DEMO"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Lobby/demo"))} />

                <RestrictedRoute exact path={"/paymentHistory"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/PaymentHistory"))} />
                <RestrictedRoute exact path={"/rebate"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerRebate"))} />
                <RestrictedRoute exact path={"/profile"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerProfile"))} />
                <RestrictedRoute exact path={"/resetPassword"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerPassword"))} />
                <RestrictedRoute exact path={"/promotion"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Promotion"))} />
                <RestrictedRoute exact path={"/referral"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Referral"))} />
                <RestrictedRoute exact path={"/referralBonus"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/ReferralBonus"))} />
                <Route exact path={"/login"} component={asyncComponent(() => import("./containers/CustomerAuthen"))} />
                <Route exact path={"/register"} component={asyncComponent(() => import("./containers/CustomerRigister"))} />
                <RestrictedAdminRoute path="/admin" component={CustomerApp} isLoggedIn={isLoggedIn} />
            </div>
        </ConnectedRouter>
    );
};

export default connect((state) => ({
    isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);
